import React, { useState, useEffect } from 'react';
import axios from 'axios';
import cusImage from '../constants/cus.png';

function Sbuttons() {
  const [salonData, setSalonData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://cms-backend-ne7x.onrender.com/api/about?url=vizagpainting');
        setSalonData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // If salonData is null, return a loading state or an empty component
  if (!salonData) {
    return <div>Loading...</div>;
  }

  const { contactNumber, email } = salonData;

  return (
    <div>
      <div className="sbuttons">
        <link
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.8.2/css/all.css"
          rel="stylesheet"
        />
        <link rel="stylesheet" href="style.css" />
        <a href={`mailto:${email}`} target="_blank" className="sbutton mail" tooltip="Mail">
          <i className="fa fa-envelope" />
        </a>
        <a href={`tel:${contactNumber}`} target="_blank" className="sbutton call" tooltip="Call">
          <i className="fa fa-phone" />
        </a>
        <a
          href={`https://api.whatsapp.com/send?phone=${contactNumber}&text=`}
          className="sbutton whatsapp"
          target="_blank"
          tooltip="WhatsApp"
        >
          <i className="fab fa-whatsapp" />
        </a>
        <a target="_blank" className="sbutton mainsbutton" tooltip-active="contact">
          <img
            src={cusImage}
            alt="Contact"
            style={{ width: 39, paddingTop: 9 }}
          />
        </a>
      </div>
    </div>
  );
}

export default Sbuttons;
