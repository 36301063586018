import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Shimmer } from 'react-shimmer';

function Header() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [salonData, setSalonData] = useState(null);

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://cms-backend-ne7x.onrender.com/api/about?url=vizagpainting');
        setSalonData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <header id="header" className={`header d-flex align-items-center sticky-top ${isMobileMenuOpen ? 'mobile-nav-active' : ''}`}>
      <div className="container-fluid position-relative d-flex align-items-center justify-content-between">
        <a href="index.html" className="logo d-flex align-items-center me-auto me-xl-0">
          {salonData ? (
            <img className="img-fluid text-center" src={salonData.logoid} alt="Salon Logo" width="100%" />
          ) : (
            <Shimmer width={100} height={50} className="shimmer-logo" />
          )}
        </a>
        <nav id="navmenu" className="navmenu">
          <ul className="nav-items">
            {salonData ? (
              <>
                <li><a href="#hero" onClick={closeMobileMenu}><strong>Home</strong></a></li>
                <li><a href="#about" onClick={closeMobileMenu}><strong>About</strong></a></li>
                <li><a href="#services" onClick={closeMobileMenu}><strong>Services</strong></a></li>
                <li><a href="#portfolio" onClick={closeMobileMenu}><strong>Portfolio</strong></a></li>
                <li><a href="#blog" onClick={closeMobileMenu}><strong>Blog</strong></a></li>
                <li><a href="#contact" onClick={closeMobileMenu}><strong>Contact</strong></a></li>
              </>
            ) : (
              <>
                <li><Shimmer width={60} height={20} /></li>
                <li><Shimmer width={60} height={20} /></li>
                <li><Shimmer width={60} height={20} /></li>
                <li><Shimmer width={60} height={20} /></li>
                <li><Shimmer width={60} height={20} /></li>
                <li><Shimmer width={60} height={20} /></li>
              </>
            )}
          </ul>
          <i
            className={`mobile-nav-toggle d-xl-none bi ${isMobileMenuOpen ? 'bi-x' : 'bi-list'}`}
            onClick={handleMobileMenuToggle}
          />
        </nav>
      </div>
    </header>
  );
}

export default Header;
