import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import axios for making HTTP requests
import { Shimmer } from 'react-shimmer'; // Import Shimmer component

function About() {
  const [salonData, setSalonData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get('https://cms-backend-ne7x.onrender.com/api/about?url=vizagpainting');
        setSalonData(response.data); // Set the fetched data to state
        setIsLoading(false); // Update loading state to false once data is fetched
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false); // Set loading to false in case of error
      }
    };

    fetchData(); // Call the fetchData function when component mounts
  }, []); // Empty dependency array ensures useEffect runs only once

  // Show shimmer effect while loading
  if (isLoading || !salonData) {
    return (
      <div className="container-xxl">
        <div className="container d-flex">
          <Shimmer width={200} height={200} />
         
        </div>
      </div>
    );
  }

  // Destructure salonData object for easier access
  const { logoid, aboutContent } = salonData;

  return (
    <div id="about">
      {/* About Start */}
      <div className="container-xxl">
        <div className="container">
          <h2>About Us</h2>
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="text-center">
                {/* Use the logo URL from fetched data */}
                <img className="img-fluid" src={logoid} alt="Salon Logo" width="50%" />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <p dangerouslySetInnerHTML={{ __html: aboutContent }} style={{ textAlign: 'justify' }}></p>
                <div className="d-flex align-items-center mb-4 pb-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
    </div>
  );
}

export default About;
