import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Shimmer } from 'react-shimmer'; // Import Shimmer component

function Services() {
  const [services, setServices] = useState([]); // State to store fetched services
  const [isLoading, setIsLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get('https://cms-backend-ne7x.onrender.com/api/services?url=vizagpainting');
        // Assuming the API response structure is like the sample provided
        if (response.data && response.data.services) {
          setServices(response.data.services); // Set the fetched services data to state
          setIsLoading(false); // Update loading state to false once data is fetched
        }
      } catch (error) {
        console.error('Error fetching services:', error);
        setIsLoading(false); // Set loading to false in case of error
      }
    };

    fetchServices(); // Call fetchServices function when component mounts
  }, []); // Empty dependency array ensures useEffect runs only once

  // Show shimmer effect while loading
  if (isLoading) {
    return (
      <div className="container mt-5">
        {/* Render shimmer effect while loading */}
        <Shimmer width="100%" height={200} />
        {/* <p>Loading...</p> */}
      </div>
    );
  }

  return (
    <div id="services">
      <section className='section1'>
        <h2>Services</h2>
        <div className="container1">
          {services.map((service, index) => (
            <div className="card" key={index}>
              <div className="card-inner" style={{ '--clr': '#fff' }}>
                <div className="box">
                  <div className="imgBox">
                    <img src={service.imageId} alt={service.serviceTitle} />
                  </div>
                  <div className="icon">
                    <a href="#" className="iconBox">
                      <span className="material-symbols-outlined">
                        arrow_outward
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="content">
                <h3><strong>{service.serviceTitle}</strong></h3>
                <p dangerouslySetInnerHTML={{ __html: service.serviceDesc }} style={{ textAlign: 'justify' }} />
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default Services;
