import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Shimmer } from 'react-shimmer'; // Import Shimmer component

function Contact() {
  const [salonData, setSalonData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://cms-backend-ne7x.onrender.com/api/about?url=vizagpainting');
        setSalonData(response.data); // Set the fetched data to state
        setIsLoading(false); // Update loading state to false once data is fetched
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false); // Set loading to false in case of error
      }
    };

    fetchData(); // Call the fetchData function when component mounts
  }, []);

  if (isLoading) {
    return (
      <section id="contact" className="contact" style={{ paddingBottom: '50px' }}>
        <div className="container">
          <div className="section-title">
            <h2>
              <Shimmer width={150} height={30} />
            </h2>
          </div>

          <div className="row" data-aos-delay="100">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-md-12">
                  <div className="info-box">
                    <Shimmer width="100%" height="100px" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box mt-4">
                    <Shimmer width="100%" height="100px" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box mt-4">
                    <Shimmer width="100%" height="100px" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="mapouter">
                <Shimmer width="100%" height="400px" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  // Destructure salonData object for easier access
  const { address, contactNumber, email, mapURL } = salonData;

  return (
    <section id="contact" className="contact" style={{ paddingBottom: '50px' }}>
      <div className="container">
        <div className="section-title">
          <h2>Contact</h2>
        </div>

        <div className="row" data-aos-delay="100">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-md-12">
                <div className="info-box">
                  <i className="bi bi-geo-alt-fill"></i>
                  <h3>Our Address</h3>
                  <p>{address}</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box mt-4">
                  <i className="bi bi-envelope-fill"></i>
                  <h3>Email Us</h3>
                  <p>{email}</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box mt-4">
                  <i className="bi bi-telephone-fill"></i>
                  <h3>Call Us</h3>
                  <p>{contactNumber}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  width="100%"
                  height="400px"
                  id="gmap_canvas"
                  src={mapURL}
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
