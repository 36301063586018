import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import axios for making HTTP requests

function Footer() {
  const [salonData, setSalonData] = useState(null);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get('https://cms-backend-ne7x.onrender.com/api/about?url=vizagpainting');
        setSalonData(response.data); // Set the fetched data to state
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Call the fetchData function when component mounts
  }, []); // Empty dependency array ensures useEffect runs only once

  // Check if data is still loading
  if (!salonData) {
    return <p></p>;
  }

  // Destructure salonData object for easier access
  const { address, contactNumber,email, logoid } = salonData;

  return (
    <footer id="footer" className="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-4 col-md-6 footer-about">
              <a href="index.html" className="logo d-flex align-items-center">
                {/* Hardcoded part */}
                <img src={salonData.logoid} alt="Logo" />
               
              </a>
              <div className="footer-contact pt-3">
                {/* Rendered from API */}
                <p>{address}</p>
                <p className="mt-3">
                  <strong>Phone:</strong> <span>{contactNumber}</span>
                </p>
                {/* Hardcoded part */}
                <p>
                  <strong>Email:</strong> <span>{email}</span>
                </p>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#">About us</a>
                </li>
                <li>
                  <a href="#">Services</a>
                </li>
                <li>
                  <a href="#">Terms of service</a>
                </li>
                <li>
                  <a href="#">Privacy policy</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="copyright text-center">
        <div className="container d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center">
        
        </div>
      </div> */}
    </footer>
  );
}

export default Footer;
