import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Shimmer } from 'react-shimmer'; // Import Shimmer component

function Hero() {
  const [slides, setSlides] = useState([]); // State to store fetched slides
  const [loading, setLoading] = useState(true); // State to track loading state

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const response = await axios.get('https://cms-backend-ne7x.onrender.com/api/Slides?url=vizagpainting');
        // Assuming the API response structure is like the sample provided
        if (response.data && response.data.images) {
          setSlides(response.data.images); // Set the fetched slides data to state
        }
        setLoading(false); // Update loading state after fetching data
      } catch (error) {
        console.error('Error fetching slides:', error);
      }
    };

    fetchSlides(); // Call fetchSlides function when component mounts
  }, []); // Empty dependency array ensures useEffect runs only once

  return (
    <div id="hero" className="container-fluid p-0 mb-5 wow fadeIn" data-wow-delay="0.1s">
      <div id="header-carousel" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
        <div className="carousel-inner">
          {loading ? (
            // Render shimmer placeholders while loading
            <>
              <div className="carousel-item active">
                <Shimmer width="100%" height={400} />
              </div>
              <div className="carousel-item">
                <Shimmer width="100%" height={400} />
              </div>
              <div className="carousel-item">
                <Shimmer width="100%" height={400} />
              </div>
            </>
          ) : (
            // Render actual slides when data is loaded
            slides.map((slide, index) => (
              <div key={slide._id} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                <img className="w-100" src={slide.imageId} alt={`Slide ${index + 1}`} />
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default Hero;

