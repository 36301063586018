import React, { useState, useEffect } from 'react';
import axios from 'axios';
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.css';
import { Shimmer } from 'react-shimmer'; // Import Shimmer component

function Gallery() {
  const [gallery, setGallery] = useState([]); // State to store fetched gallery
  const [isLoading, setIsLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchGallery = async () => {
      try {
        const response = await axios.get('https://cms-backend-ne7x.onrender.com/api/gallery?url=vizagpainting');
        // Assuming the API response structure is like the sample provided
        if (response.data && response.data.images) {
          setGallery(response.data.images); // Set the fetched gallery data to state
          setIsLoading(false); // Update loading state to false once data is fetched
        }
      } catch (error) {
        console.error('Error fetching gallery:', error);
        
      }
    };

    fetchGallery(); // Call fetchGallery function when component mounts

    // Initialize GLightbox
    const lightbox = GLightbox({
      touchNavigation: true,
      loop: true,
    });

    return () => {
      lightbox.destroy(); // Cleanup the lightbox on component unmount
    };
  }, []); // Empty dependency array ensures useEffect runs only once


  if (isLoading) {
    return (
      <div className="container mt-5">
        {/* Render shimmer effect while loading */}
        <div className="row g-0 isotope-container" data-aos-delay={200}>
          {Array.from({ length: 4 }).map((_, index) => (
            <div key={index} className="col-xl-3 col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
              <div className="portfolio-content h-100">
                <Shimmer width="100%" height={300} />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
  

  return (
    <div>
      <>
        {/* Portfolio Section */}
        <section id="portfolio" className="portfolio section">
          {/* Section Title */}
          <div className="container section-title">
            <h2>Portfolio</h2>
          </div>
          {/* End Section Title */}
          <div className="container-fluid">
            <div className="isotope-layout" data-default-filter="*" data-layout="masonry" data-sort="original-order">
              {/* End Portfolio Filters */}
              <div className="row g-0 isotope-container" data-aos-delay={200}>
                {gallery.map((image, index) => (
                  <div key={index} className="col-xl-3 col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
                    {/* <a href={image.imageId} target='_blank' data-gallery="portfolio-gallery-app" className="glightbox preview-link"> */}
                    <div className="portfolio-content h-100">
                      <img src={image.imageId} className="img-fluid" alt={image.alt || 'Gallery Image'} />
                      <div className="portfolio-info">
                        
                          
                 
                      </div>
                    </div>
                    {/* </a> */}
                  </div>
                ))}
                {/* End Portfolio Item */}
              </div>
              {/* End Portfolio Container */}
            </div>
          </div>
        </section>
        {/* /Portfolio Section */}
      </>
    </div>
  );
}

export default Gallery;
