import About from "./components/About";
import Contact from "./components/Contact";
import Fotter from "./components/Fotter";
import Gallery from "./components/Gallery";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Sbuttons from "./components/Sbuttons";
import Services from "./components/Services";


function App() {
  return (
    <>
    <Header />
    <Hero/>
    <About />
    <Services />
    <Gallery />
    <Contact />
    <Fotter />
    <Sbuttons />
    </>
  

  );
}

export default App;
